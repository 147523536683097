/* eslint-disable import/no-named-as-default, react/prop-types */
import { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import get from 'lodash/get';
import isEqual from 'lodash/fp/isEqual';

import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';

import IframeResizer from 'iframe-resizer-react';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { getHomeRoute } from '../setup/configReducer';

import ReleaseNotesContainer from '../components/About/ServiceInfoContainer';

import {env, getRuntimeConfig} from '../env';
import {
    ANALYSIS_PORTAL,
    COMPANY_CARDS_TAB,
    MANUAL_DOWNLOAD_TAB,
    MANUAL_TAB,
    OVERVIEW_TAB,
    REMOTE_DOWNLOAD_TAB,
} from '../constants/archive';

import AdministrationSettingsContainer from '../components/About/AdministrationSettingsContainer';

import { getLocale } from '../common/tacho-lang/selectors';
import shouldEnableAnalysisPortal from '../utils/shouldEnableAnalysisPortal';

const { isLocalEnv, isRunningOnProd } = env;
const serviceInfoItem = <ReleaseNotesContainer/>;

const administrationSettings = <AdministrationSettingsContainer/>;

const notifications = <RioNotifications environment={process.env.NODE_ENV} />;

const appNavigator =
            <IframeResizer
                className={'iFrameResizer'}
                src={'https://menu.rio.cloud/'}
                checkOrigin={false}
            />;

class AppHeader extends Component {
    shouldComponentUpdate(nextProps) {
        const arePropsEqual = isEqual(
            this.props,
            nextProps,
        );

        return !arePropsEqual;
    }

    render() {
        const { homeRoute, redirectUri, permissions, userLocale, analysisPortalAccount } = this.props;

        const appTitle = <FormattedMessage id={'module.name'} defaultMessage={'Compliant'}/>;
        const accountMenu =
            <DefaultUserMenu
                environment={process.env.NODE_ENV} // eslint-disable-line no-process-env
                logoutRedirectUri={redirectUri}
                localUserMenu={isLocalEnv}
                locale={userLocale}
            />;

        // Define the navigation items of the current application
        const navItems = [
            {
                // permission:'remotedownload.overview',
                key: 'overview',
                route: <NavLink to={{ pathname: `/${OVERVIEW_TAB}`, search: this.props.location.search}}>
                    <FormattedMessage
                        id={'subModuleName.overview'}
                        defaultMessage={'Overview'}
                    />
                </NavLink>,
            },
            {
                // permission: 'remotedownload.archive',
                key: 'archive',
                route: <NavLink to={{ pathname: `/${REMOTE_DOWNLOAD_TAB}`, search: this.props.location.search}}>
                    <FormattedMessage
                        id={'subModuleName.archive'}
                        defaultMessage={'Archive'}
                    />
                </NavLink>,
            },
            // eslint-disable-next-line multiline-ternary
            permissions.includes('manual-download.perform') ? {
                key: 'manualDownload',
                route: <NavLink to={{ pathname: `/${MANUAL_DOWNLOAD_TAB}`, search: this.props.location.search}}>
                    <FormattedMessage
                        id={'archive.manualDownload'}
                        defaultMessage={'Manual Download'}
                    />
                </NavLink>,
            } : null,
            // eslint-disable-next-line multiline-ternary
            permissions.includes('rda-assign.read') ? {
                key: 'companyCards',
                route: <NavLink to={{ pathname: `/${COMPANY_CARDS_TAB}`, search: this.props.location.search}}>
                    <FormattedMessage
                        id={'archive.companyCards'}
                        defaultMessage={'Company Cards'}
                    />
                </NavLink>,
            } : null,
            // eslint-disable-next-line multiline-ternary
            permissions.includes('rda-installer.get') ? {
                // permission: 'rda-installer.get'
                key: 'manual',
                route: <NavLink to={{ pathname: `/${MANUAL_TAB}`, search: this.props.location.search}}>
                    <FormattedMessage
                        id={'administration.manual'}
                        defaultMessage={'Guidance'}
                    />
                </NavLink>,
            } : null,
            // eslint-disable-next-line no-negated-condition
            shouldEnableAnalysisPortal() && analysisPortalAccount.isActive ?
                {
                    key: 'analysisportal',
                    route: <Link
                        target={'_blank'}
                        to={{ pathname: `/${ANALYSIS_PORTAL}`, search: this.props.location.search}}>
                        <FormattedMessage
                            id={'administration.analysisportal'}
                            defaultMessage={'Analysis Portal'}
                        />
                        <span className={'rioglyph rioglyph-link'}>
                        </span>
                        {!isRunningOnProd && <span
                            style={{float: 'right'}}
                            className={'label label-success label-condensed'}><FormattedMessage id={'beta'} /></span>}
                    </Link>,
                } :
                null,
            shouldEnableAnalysisPortal() && !analysisPortalAccount.isActive ?
                {
                    key: 'analysisportal',
                    route: <Link
                        target={'_blank'}
                        to={{
                            pathname: `/${this.props.location.pathname}`,
                            search: `?isSettingsSidebarOpen&selectedTab=analysisPortal`,
                        }}>
                        <FormattedMessage
                            id={'administration.analysisportal'}
                            defaultMessage={'Analysis Portal'}
                        />
                        <span className={'rioglyph rioglyph-link'}>
                        </span>
                        {!isRunningOnProd && <span
                            style={{float: 'right'}}
                            className={'label label-success label-condensed'}><FormattedMessage id={'beta'} /></span>}
                    </Link>,
                } :
                null,
        ];
        const filteredNavItems = navItems.filter(item => item !== null);
        const barItems = [administrationSettings, serviceInfoItem, notifications, accountMenu];
        return (
            <ApplicationLayout.Header>
                <ApplicationHeader
                    label={appTitle}
                    homeRoute={<a href={homeRoute} />}
                    appNavigator={appNavigator}
                    navItems={filteredNavItems}
                    actionBarItems={barItems}
                />
            </ApplicationLayout.Header>
        );
    }
}

const mapStateToProps = state => ({
    userLocale: getLocale(state),
    homeRoute: getHomeRoute(state),
    redirectUri: getRuntimeConfig(window).login.redirectUri,
    permissions: get(
        state,
        'tachographservices.common.permissions',
        [],
    ),
    vehiclesWithSubscription: get(
        state,
        'tachographservices.tree.vehiclesWithSubscription',
    ),
    analysisPortalAccount: get(
        state,
        'tachographservices.common.analysisPortalAccount',
        {ident: '', isActive: false},
    ),
});

export default withRouter(connect(mapStateToProps)(AppHeader));
